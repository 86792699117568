import React, { useContext, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import BasketContext from '../../context/basket-context';

import './cart.scss';
import { CartItem } from '../cart-item/cart-item';

export const Cart = () => {
  const { basket, dispatch } = useContext(BasketContext);

  const goToCheckout = (e) => {
    updateBasketAction(e);

    navigate('/checkout');
  };

  const updateBasketAction = (e) => {
    e.preventDefault();

    dispatch({
      type: 'UPDATE_BASKET',
      payload: basket,
    });
  };

  const countItems = () => {
    return basket.length;
  };

  const isBasketEmpty = () => {
    return basket.length === 0;
  };

  return (
    <div className="cart-module">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-lg-8">
            <Card className="mb-4">
              <Card.Body>
                <form id="product-form">
                  <h5 className="mb-4 font-weight-bold">
                    Basket (<span>{countItems()}</span> items)
                  </h5>
                  {isBasketEmpty() ? (
                    <p>Your basket is empty.</p>
                  ) : (
                    <>
                      {basket
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .map((product, index) => (
                          <CartItem product={product} key={index} />
                        ))}
                    </>
                  )}
                </form>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <aside>
              <h5 className="mb-4 font-weight-bold">Have questions?</h5>
              <p>Our customer service team is happy to help!</p>
              <p className="text-small">
                Call us on 0333 222 70 70 or email us at{' '}
                <a
                  href="mailto:duluxtrade.advice@akzonobel.com"
                  className="email-address"
                  role="link"
                  aria-label="email us at"
                >
                  duluxtrade.advice@akzonobel.com
                </a>
              </p>
              <h5 className="mb-4 mt-4 font-weight-bold">Deliveries</h5>
              <p className="text-small">
                Items are usually dispatched the same working day (Mon-Fri) if
                ordered before 12 noon, otherwise dispatched the next working
                day.
              </p>
              <Link
                to="/"
                className="btn btn-tertiary btn-block mb-2"
                role="button"
              >
                Continue Shopping
              </Link>
              <Button
                className="btn-block grow"
                variant="secondary"
                type="button"
                form="product-form"
                disabled={isBasketEmpty()}
                onClick={(e) => goToCheckout(e)}
              >
                Go to checkout
              </Button>
            </aside>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
