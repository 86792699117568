import React from 'react';
import { Container } from 'react-bootstrap';

import { Header } from '../layout/header';
import { Footer } from '../layout/footer';
import { Cart } from '../components/cart/cart';
import { HeroPrimary } from '../components/hero-primary';
import { SectionHeading } from '../components/section-heading';
import { Logos } from '../components/logos';
import CookieConsent from '../layout/cookie-consent';

export default function Basket() {
  return (
    <Container fluid className="p-0">
      <Header />
      <CookieConsent />
      <HeroPrimary heroTitle="Basket" />
      <SectionHeading sectionHeading="Check your basket" />
      <Cart />
      <Logos />
      <Footer />
    </Container>
  );
}
