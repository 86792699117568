import React, { useState, useContext } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { QtyCounter } from '../cart/qty-counter';
import BasketContext from '../../context/basket-context';
import { BASKET_CONSTANTS } from '../../shared/constants/basket-constants';

export const CartItem = ({ product }) => {
  const [showRemoveItemDialog, setShowRemoveItemDialog] = useState(false);
  const handleCloseRemoveItemDialog = () => setShowRemoveItemDialog(false);
  const handleShowRemoveItemDialog = () => setShowRemoveItemDialog(true);

  const { dispatch } = useContext(BasketContext);

  const removeItemHandler = (product) => {
    dispatch({
      type: BASKET_CONSTANTS.REMOVE_ITEM,
      payload: product,
    });

    handleCloseRemoveItemDialog();
  };

  const showProductLimitMessage = (maximumAmount) => {
    return maximumAmount >= 1;
  };

  return (
    <Row className="mb-4">
      <Col md={5} lg={3}>
        <img src={product.image} alt={product.name} className="img-fluid" />
      </Col>
      <Col md={7} lg={9}>
        <Row className="d-flex justify-content-between mt-3">
          <Col md={8}>
            <h5 className="font-weight-bold">{product.name}</h5>
            <p className="mb-3 text-muted text-uppercase small">
              {product.code}
            </p>
            <p className="mb-3 text-muted small">{product.name}</p>
            {showProductLimitMessage(product.maximumAmount) && (
              <p className="mb-3 text-muted alert alert-info small">
                Please call us if you need to order more than
                <span className="px-1">{product.maximumAmount}</span>
              </p>
            )}
          </Col>
          <Col className="text-right">
            <QtyCounter item={product} />
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Button
              variant="tertiary"
              className="p-0 small text-uppercase"
              size="sm"
              onClick={handleShowRemoveItemDialog}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="fa mr-1"
                aria-hidden="true"
              />
              Remove item
            </Button>
          </div>
        </div>
      </Col>

      <Modal show={showRemoveItemDialog} onHide={handleCloseRemoveItemDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Remove item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove the item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRemoveItemDialog}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              removeItemHandler(product);
            }}
          >
            Yes, remove it
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
