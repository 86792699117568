import React, { useContext, useEffect, useRef, useState } from 'react';
import BasketContext from '../../context/basket-context';

import './qty-counter.scss';
import { BASKET_CONSTANTS } from '../../shared/constants/basket-constants';

export const QtyCounter = ({ item }) => {
  const [itemQty, setItemQty] = useState(item.quantity);
  const { dispatch } = useContext(BasketContext);
  const inputRef = useRef();
  const minItemQuantity = 1;

  useEffect(() => {
    setItemQty(item.quantity);
  }, [item]);

  useEffect(() => {
    item.quantity = itemQty;
    updateItemQuantityInBasket();
  }, [itemQty]);

  const updateValue = (e) => {
    let newQuantity = parseInt(e.target.value) || '';
    setItemQty(newQuantity);
  };

  const validateInput = () => {
    const input = inputRef.current;

    return input.reportValidity() && itemQty;
  };

  const updateItemQuantityInBasket = () => {
    if (!validateInput()) {
      return;
    }

    dispatch({
      type: BASKET_CONSTANTS.UPDATE_ITEM_QUANTITY,
      payload: {
        ...item,
        newQuantity: itemQty,
      },
    });
  };

  const checkIfNull = () => {
    const input = inputRef.current;

    if (!input.value) {
      setItemQty(0);
      input.value = 0;
      input.reportValidity();
    }
  };

  const incrementItemQtyHandler = (e, product) => {
    e.preventDefault();

    dispatch({
      type: BASKET_CONSTANTS.INCREMENT_ITEM_QTY,
      payload: product,
    });

    setItemQty(itemQty + 1);
  };

  const decrementItemQtyHandler = (e, product) => {
    e.preventDefault();

    dispatch({
      type: BASKET_CONSTANTS.DECREMENT_ITEM_QTY,
      payload: product,
    });

    setItemQty(itemQty - 1);
  };

  return (
    <div className="qty-counter">
      <button
        className="input-number-decrement"
        disabled={itemQty === minItemQuantity}
        onClick={(e) => decrementItemQtyHandler(e, item)}
        type="button"
      >
        –
      </button>
      <input
        className="input-number"
        id="input-number"
        ref={inputRef}
        type="number"
        min={minItemQuantity}
        max={item.maximumAmount === 0 ? '' : item.maximumAmount}
        value={itemQty}
        onChange={(e) => updateValue(e)}
        onBlur={() => checkIfNull()}
      />
      <button
        className="input-number-increment"
        onClick={(e) => incrementItemQtyHandler(e, item)}
        disabled={itemQty >= item.maximumAmount && item.maximumAmount !== 0}
        type="button"
      >
        +
      </button>
    </div>
  );
};
